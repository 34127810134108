import Service from "@/js/service.js";

const service = new Service(
  `${process.env.VUE_APP_COCKPIT_SPREADSHEET_SERVICE}/cockpit-spreadsheet-service`
);

const cockpitSpreadsheetService = {
  getSpreadsheetVariables(thenCb, errorCb, params) {
    service.getRequest(1, "variables", params, thenCb, errorCb);
  },
  getSpreadsheetVariable(id, thenCb, errorCb) {
    service.getRequest(1, "variable/{id}", null, thenCb, errorCb, id);
  },
  createSpreadsheetVariable(variable, thenCb, errorCb) {
    service.putRequest(1, "variable", variable, thenCb, errorCb);
  },
  updateSpreadsheetVariable(variable, thenCb, errorCb) {
    service.patchRequest(
      1,
      "variable/{id}",
      variable,
      thenCb,
      errorCb,
      variable.id
    );
  },
  deleteSpreadsheetVariable(id, thenCb, errorCb) {
    service.deleteRequest(1, "variable/{id}", null, thenCb, errorCb, id);
  },
};

export default cockpitSpreadsheetService;
