import { ref, computed } from "vue";
import cockpitSpreadsheetService from "@/js/services/CockpitSpreadsheetService";
import debounce from "debounce";

// =TEMPORARY=
function addFielldsThatAreMissing(variable) {
  if (!variable.type) {
    variable.type = "text"
  }
  return variable;
}

const ITEMS_PER_PAGE = 25;

class DuplicationError extends Error {
  constructor(message) {
    super(message);
    this.name = "DuplicationError";
  }
}

export function useVariables() {
  const isLastPage = ref(false)
  const variables = ref([])
  const currentPage = ref(0)

  const isNewVariable = (_v) => !_v.id;
  const isDuplicationError = (error) => error.response.data.message.toLowerCase().includes("duplicate entry");
  const updateVariableInList = (variable) => {
    const index = variables.value.findIndex(v => v.id === variable.id);
    if (index > -1) {
      variables.value.splice(index, 1, variable);
    }
  }
  const addNewVariableToList = (variable) => {
    variables.value.splice(0, 1, variable)
  }

  const createVariable = async (variable, { updateList = true } = {}) => {
    const _v = addFielldsThatAreMissing(variable);
    try {
      return await new Promise((resolve, reject) => {
        cockpitSpreadsheetService.createSpreadsheetVariable(
          _v,
          id => {
            _v.id = id;
            if (updateList) {
              addNewVariableToList(_v)
            }
            resolve(_v)
          },
          error => reject(error)
        );
      })
    } catch (err) {
      if (isDuplicationError(err)) {
        throw new DuplicationError("Variable with this name already exists")
      }
      throw err
    }
  }

  const fetchVariables = () => {
    const params = {
      page: currentPage.value + 1,
      "items-per-page": ITEMS_PER_PAGE,
    };
    return new Promise((resolve, reject) => {
      cockpitSpreadsheetService.getSpreadsheetVariables(
        incomingVars => {
          if (incomingVars.length) {
            currentPage.value += 1;
            variables.value = [...variables.value, ...incomingVars];
          } else {
            isLastPage.value = true;
          }
          resolve(incomingVars);
        },
        error => reject(error),
        params
      );
    })
  }

  const updateOldVariable = (variable, { updateList = true } = {}) => {
    const _v = addFielldsThatAreMissing(variable);
    return new Promise((resolve, reject) => {
      cockpitSpreadsheetService.updateSpreadsheetVariable(
        _v,
        response => {
          if (updateList) {
            updateVariableInList(_v)
          }
          resolve(response)
        },
        error => reject(error)
      );
    });
  }

  const removeVariableFromList = (variable) => {
    if (isNewVariable(variable)) {
      variables.value.shift();
      return
    }
    const index = variables.value.findIndex(v => v.id === variable.id);
    if (index > -1) {
      variables.value.splice(index, 1);
    }
  }

  const deleteVariable = (variable, { updateList = true } = {}) => {
    if (isNewVariable(variable)) {
      removeVariableFromList(variable)
      return Promise.resolve();
    }
    return new Promise((resolve, reject) => {
      cockpitSpreadsheetService.deleteSpreadsheetVariable(
        variable.id,
        response => {
          if (updateList) {
            removeVariableFromList(variable);
          }
          resolve(response)
        },
        error => reject(error)
      );
    });
  }

  const doVariableSearch = debounce((searchTerm) => {
    const params = {
      search: searchTerm,
    };
    return new Promise((resolve, reject) => {
      cockpitSpreadsheetService.getSpreadsheetVariables(
        incomingVars => {
          variables.value = incomingVars;
          resolve(incomingVars);
        },
        error => reject(error),
        params
      );
    })
  }, 500)
  const $searchText = ref('')
  const searchText = computed({
    get() {
      return $searchText.value
    },
    set(value) {
      $searchText.value = value
      doVariableSearch(value)
    }
  })

  return {
    variables,
    currentPage,
    isLastPage,
    isNewVariable,
    fetchVariables,
    updateOldVariable,
    deleteVariable,
    createVariable,
    DuplicationError,
    addNewVariableToList,
    doVariableSearch,
    searchText,
    removeVariableFromList,
    updateVariableInList,
  }
}
