import { ref } from "vue";

export function useVariable(variable) {
  const variableName = ref(variable.name);
  const variableValue = ref(variable.value);

  const getUpdatedVariable = () => {
    // eslint-disable-next-line no-undef
    const _v = structuredClone(variable);
    _v.name = variableName.value;
    _v.value = variableValue.value;
    if (_v.id === '') {
      delete _v.id;
    }
    return _v;
  };

  const isValidName = (name) => name && name.length > 0;
  const isValidVariable = (_v) => isValidName(_v.name)

  const variableNameRules = [
    name => isValidName(name),
    name => name.length <= 255,
  ];

  return {
    isValidVariable,
    variableName,
    variableValue,
    getUpdatedVariable,
    variableNameRules,
  }
}
